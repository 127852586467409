import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { content } from './content';
import Footer from '../Footer';

import { MainHeader, ExternalLink } from '../Styled';
import {
  PrivacyWrapper,
  Section,
  SectionTitle,
  SectionParagraph,
  ReadMoreLink,
} from './styles';

type State = {
  isIE: boolean,
};

export default class Privacy extends React.Component<{}, State> {
  state = {
    isIE: false,
  };

  componentDidMount() {
    this.setState({
      isIE: /*@cc_on!@*/ false || !!document.documentMode,
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Mcon | Integritetspolicy</title>
        </Helmet>
        <PrivacyWrapper>
          <MainHeader colorFrom="#000" colorTo="#000" isIE={this.state.isIE}>
            Integritetspolicy
          </MainHeader>
          <div>
            {content.map(section => (
              <Section key={section.header}>
                <SectionTitle>{section.header}</SectionTitle>
                {section.paragraphs.map((paragraph, idx) => (
                  <SectionParagraph key={idx}>{paragraph}</SectionParagraph>
                ))}
              </Section>
            ))}
          </div>
          <ReadMoreLink>
            Läs mer om allmänna villkor om rådgivningstjänster{' '}
            <ExternalLink
              href="./privacy/privacy.pdf"
              target="_blank"
              id="test-id-privacy-more-link"
              rel="noopener noreferrer"
            >
              här.
            </ExternalLink>
          </ReadMoreLink>
        </PrivacyWrapper>
        <Footer />
      </Fragment>
    );
  }
}
