export const content = [
  {
    header: 'Bakgrund',
    paragraphs: [
      'När personuppgifter behandlas har den personuppgiftsansvarige en skyldighet att lämna viss information till de registrerade. Med anledning av uppdraget kommer personuppgifter att behandlas av Mcon AB och eventuellt av dess nätverksbyråer. Av denna anledning lämnas följande information.',
    ],
  },
  {
    header: 'Mcon AB’s behandling av personuppgifter',
    paragraphs: [
      'Mcon AB kommer att behandla personuppgifter enligt gällande rätt. De personuppgifter som kommer att behandlas erhålls från kunden, dess koncernbolag (om tillämpligt), eller annan, t.ex. Skatteverket, Bolagsverket eller allmänt tillgängliga källor och avser behöriga företrädare och andra personer vars personuppgifter behövs för att hantera kundrelationen samt verklig huvudman. Personuppgifterna behandlas inför antagandet av kunder och/eller uppdrag och med anledning av utförandet av uppdraget för att vidta kvalitetskontroller, kontroll av intressekonflikter, åtgärder enligt lagen (2017:630) om åtgärder mot penningtvätt och finansiering av terrorism ("penningtvättslagen") samt för att dokumentera vidtagna åtgärder. Sådan behandling är nödvändig för att fullgöra de rättsliga förpliktelser som åvilar [Revisionsbyrån, eller en inom byrån personvald revisor/Uppdragstagaren] som åtagit sig att utföra uppdraget [och är nödvändig för Uppdragstagarens berättigade intresse av att uppfylla dess professionella skyldighet]. Mcon AB kan även komma att behandla personuppgifter för andra risk management-åtgärder (såsom exempelvis försäkringsärenden) samt för att utföra den interna finansiella redovisningen. Denna behandling är nödvändig för Mcon AB’s berättigade intresse av att hantera risker och eventuella krav.',
      'De kategorier av personuppgifter som kan komma att behandlas för ovan nämnda ändamål är bl.a. kontaktuppgifter som namn, adress, personnummer/samordningsnummer, telefonnummer, e-postadress samt uppgifter om avdelningstillhörighet och befattning. I samband med registrering av kunden kan Mcon AB även komma att behandla kopior av identitetshandlingar för de personer som företräder uppdragsgivaren inom ramen för de kundkännedomsåtgärder som ska vidtas i enlighet med penningtvättslagen.',
      'Mcon AB kan även komma att behandla personuppgifter som namn, avdelningstillhörighet, befattning och e-postadresser för att informera om seminarier och andra event som Mcon AB anordnar samt för att skicka ut nyhetsbrev och annan marknadsföring. Behandling för dessa ändamål är nödvändig för Mcon AB berättigade intresse av att kunna nå ut till anställda hos kunder som kan vara intresserade av event, marknadsföring och nyheter inom områden som är relevanta för dessa personers befattningar.',
    ],
  },
  {
    header: 'Mottagare av informationen',
    paragraphs: [
      'Mcon AB är skyldig att tillse att den information som behandlas med anledning av uppdraget inte blir tillgänglig för obehöriga, vilket innebär att personuppgifter kommer att behandlas konfidentiellt.',
      'Mcon AB kan komma att lämna ut personuppgifterna till nätverksbyråer eller annan som anlitas av Mcon AB i syfte att kontrollera och upprätthålla Mcon AB’s opartiskhet och självständighet, att utföra kvalitetskontroller och vidta övriga risk management-åtgärder samt för att skicka ut inbjudningar till event och annat marknadsföringsmaterial. Mcon AB kan även komma att lämna ut personuppgifterna till försäkringsbolag eller juridiska rådgivare i samband med ett rättsligt förfarande i den mån det krävs för att Mcon AB ska kunna ta tillvara sina rättmätiga intressen eller till annan mottagare om sådan skyldighet föreligger enligt gällande lagar och regler, professionell skyldighet eller myndighetsbeslut.',
    ],
  },
  {
    header: 'Säkerhet vid behandling av personuppgifter',
    paragraphs: [
      'Mcon AB ansvarar enligt gällande rätt för att de personuppgifter som behandlas skyddas genom erforderliga tekniska och organisatoriska säkerhetsåtgärder med beaktande av vad som är lämpligt i förhållande till personuppgifternas karaktär och känslighet. [Revisionsbyråns/Uppdragstagarens] system och organisation är ordnade så att obehöriga personer inte har tillgång till de personuppgifter som behandlas med anledning av uppdraget.',
    ],
  },
  {
    header: 'Rättigheter som registrerad',
    paragraphs: [
      'Registrerade har i vissa fall rätt att begära att få information om huruvida personuppgifter som rör den registrerade behandlas och i så fall rätt att få tillgång till personuppgifterna i form av ett s.k. registerutdrag. Registrerade har vidare ofta rätt att få felaktiga personuppgifter som rör den registrerade rättade. Vidare kan registrerade ha rätt att få sina personuppgifter raderade, rätt att begära begränsning av behandlingen av personuppgifter som rör den registrerade eller invända mot sådan behandling. Registrerade har också rätt att inge klagomål till en tillsynsmyndighet om behandlingen.',
      'När det gäller personuppgifter som behandlas i samband med antagande av kunder och uppdrag samt med anledning av uppdraget, så är Mcon AB skyldig att bevara dokumentationen i detta avseende under minst tio år. Detta medför att det inte är tillåtet att radera personuppgifter som ingår i sådan dokumentation dessförinnan och ibland är det inte heller tillåtet att rätta uppgifterna. Av nämnda skäl är det inte möjligt för Mcon AB att i sådana fall på begäran från en registrerad begränsa eller inskränka behandlingen av personuppgifterna. När det gäller Mcon AB’s personuppgiftsbehandling för marknadsföringsändamål har registrerade dock rätt att begära radering, rättelse, begränsning och att invända mot behandling av sina personuppgifter för sådana ändamål.',
    ],
  },
]
