import styled from 'styled-components';
import { media } from '../../utils/style-utils';

export const PrivacyWrapper = styled.div`
  margin: 0px 20px 20px;
  padding-top: 100px;
  text-align: center;

  ${media.tablet`
    margin: 0px 60px 60px;
  `};

  ${media.desktop`
    max-width: 800px;
    padding-top: 200px;
    margin: 0px auto 0 auto;
  `};

  ${media.hd`
    max-width: 900px;
  `};
`;
export const Section = styled.section`
  margin: 40px 0;
  text-align: left;

  &:first-child {
    margin: 100px 0 40px 0;
  }

  ${media.tablet`
  margin: 60px 0;

  &:first-child {
    margin: 150px 0 60px 0;
  }
`};

  ${media.hd`
  margin: 80px 0;

  &:first-child {
    margin: 200px 0 80px 0;
  }
`};
`;

export const SectionTitle = styled.h4`
  font-weight: 500;
  font-size: 20px;

  ${media.tablet`
  font-size: 24px;
`};

  ${media.hd`
  font-size: 28px;
`};
`;

export const SectionParagraph = styled.p`
  font-size: 16px;
  margin: 20px 0;

  ${media.tablet`
  font-size: 18px;
  margin: 24px 0;
`};

  ${media.hd`
  font-size: 20px;
  margin: 28px 0;
`};
`;

export const ReadMoreLink = styled.p`
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 100px;

  ${media.desktop`
  font-size: 18px;
`};
`;
